import React, { useState, useEffect } from "react";

import { Header } from "../component";
import BodyWidget from "../component/BodyWidget";
import customersService from "../api/customers.api";
import RegisterDatatable from "../component/mui-components/RegisterDatatable";

const columns = [
  { field: "_id", headerName: "ID", width: 70 },
  {
    field: "firstname",
    headerName: "First Name",
    width: 230,
  },
  {
    field: "lastname",
    headerName: "Last Name",
    width: 230,
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },
];

const Employees = () => {
  const [data, setData] = useState([]);
  const configData = { page: 1, limit: 10 };

  useEffect(() => {
    const fetchData = async () => {
      const results = await customersService.getRegisteredCustomer(configData);
      setData(results.data);
    };
    fetchData();
    ///setData(results.data);
  }, []);

  return (
    <BodyWidget>
      <Header category="Page" title="Register Clients" />
      <RegisterDatatable columns={columns} data={data} isLoadingData={false} />
    </BodyWidget>
  );
};
export default Employees;

import React from "react";
import { ChartsHeader, LineChart } from "../../component";
import BodyWidget from "../../component/BodyWidget";

const Bar = () => {
  return (
    <BodyWidget>
      <ChartsHeader category="Line" title="Bar Chart" />
      <div className="w-full">
        <LineChart />
      </div>
    </BodyWidget>
  );
};

export default Bar;

import "./custom-css/datatable.scss";
import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
// import { userColumns, userRows } from "../../api/order.api";

import { useState, useRef } from "react";
import { Button as MuiButton } from "@mui/material";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { useNavigate } from "react-router-dom";

const RegisterDatatable = ({ columns, data, isLoadingData }) => {
  const [ndata, setNData] = useState([]);
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    isLoading: isLoadingData,
    data: data,
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const [nbRows, setNbRows] = useState(3);
  // const removeRow = () => setNbRows((x) => Math.max(0, x - 1));
  const addRow = () => setNbRows((x) => Math.min(100, x + 1));
  const [pageSize, setPageSize] = useState(10);
  const effectRan = useRef(false);

  const handleDelete = (id) => {
    console.log(id);
    setNData(data.filter((item) => item._id !== id));
  };

  const handleDisplayAction = (id) => {
    navigate(`/customers/${id}`);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <MuiButton
              variant="outlined"
              size="small"
              className="font-black"
              endIcon={<ArrowForwardOutlinedIcon />}
            >
              View
            </MuiButton>

            {/* <div
              className="deleteButton"
              onClick={() => handleDelete(params.row._id)}
            >
              Delete
            </div> */}
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
        <MuiButton
          variant="outlined"
          size="small"
          className="font-black"
          endIcon={<FolderOpenOutlinedIcon />}
          onClick={() => addRow()}
        >
          View Customers
        </MuiButton>
      </Stack>

      {/* <DataGrid
        className="datagrid"
        rows={data}
        // columns={userColumns.concat(actionColumn)}
        columns={columns}
        pageSize={9}
        rowsPerPageOptions={[9]}
        keepNonExistentRowsSelected
        checkboxSelection
      /> */}
      <DataGrid
        autoHeight
        rows={data}
        rowCount={pageState.total}
        loading={isLoadingData}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        getRowId={(row) => row._id}
        page={pageState.page}
        pageSize={pageSize}
        paginationMode="server"
        onPageChange={(newPage) =>
          setPageState((old) => ({
            ...old,
            page: newPage,
          }))
        }
        onPageSizeChange={(newPageSize) =>
          setPageSize((old) => ({
            ...old,
            pageSize: newPageSize,
          }))
        }
        columns={columns.concat(actionColumn)}
      />
    </div>
  );
};

export default RegisterDatatable;

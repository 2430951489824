import axios from "./api";

//Project User
const getCustomerProject = async (customerData) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(
    "orders/" + customerData.page + "/" + customerData.limit,
    { params: customerData, headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const getRegisteredCustomer = async (customerData) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(
    "customers/" + customerData.page + "/" + customerData.limit,
    { params: customerData, headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const getProjectDetails = async (customerData) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(`orders/details/${customerData.id}`, {
    params: customerData,
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const downloadFile = async (customerData) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(`orders/project/${customerData.id}`, {
    params: customerData,
    responseType: "blob",
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const getProjectCodeFiles = async (projectCode) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(`orders/project/files/${projectCode.code}`, {
    // params: projectCode,
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const customersService = {
  getCustomerProject,
  getRegisteredCustomer,
  getProjectDetails,
  downloadFile,
  getProjectCodeFiles,
};

export default customersService;

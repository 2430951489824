import React from "react";
import BodyWidget from "../component/BodyWidget";

// import { ordersData, contextMenuItems, ordersGrid } from "../data/dummy";
import { Header } from "../component";
// import EnhancedTable from "../component/mui-components/EnhancedTable";
import Datatable from "../component/mui-components/Datatable";

const Orders = () => {
  //const editing = { allowDeleting: true, allowEditing: true };
  return (
    <BodyWidget>
      <Header category="Page" title="Orders" />
    </BodyWidget>
  );
};
export default Orders;

import React, { createContext, useContext, useState } from "react";
import { Navigate } from "react-router-dom";

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("#03C9D7");
  const [currentMode, setCurrentMode] = useState("Light");
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [translateEffect, setTranslateEffect] = useState("translate-x-0");

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem("themeMode", e.target.value);
  };

  const HandleRequireAuthEvent = ({ children }) => {
    console.log("trying logging");
    return isLoggedIn ? children : <Navigate to="/signin" />;
  };

  const setAuthentication = (logged) => {
    setIsLoggedIn(logged);
  };

  const setTranslateEvent = (active) => {
    active
      ? setTranslateEffect("translate-x-full duration-300")
      : setTranslateEffect("translate-x-0 duration-75");
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem("colorMode", color);
  };

  const handleClick = (clicked) => {
    setIsClicked({ ...initialState, [clicked]: true });
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider
      value={{
        currentColor,
        currentMode,
        activeMenu,
        screenSize,
        setScreenSize,
        handleClick,
        isClicked,
        initialState,
        setIsClicked,
        setActiveMenu,
        setCurrentColor,
        setCurrentMode,
        setMode,
        setColor,
        themeSettings,
        setThemeSettings,
        setAuthentication,
        isLoggedIn,
        setIsLoggedIn,
        setTranslateEvent,
        setTranslateEffect,
        translateEffect,
        HandleRequireAuthEvent,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);

import React from "react";
import { BrowserRouter } from "react-router-dom";

import "./App.css";
import { Dashboard, SignIn } from "./pages";
import { useStateContext } from "./contexts/ContextProvider";

const App = () => {
  const { isLoggedIn } = useStateContext();
  return (
    <div>
      <BrowserRouter>{isLoggedIn ? <Dashboard /> : <SignIn />}</BrowserRouter>
    </div>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Header } from "../component";
import BodyWidget from "../component/BodyWidget";
import customersService from "../api/customers.api";
import FileDownload from "js-file-download";
import Axios from "axios";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import { BASE_URL } from "../api/api";

const zip = require("jszip")();

function getBase64(url) {
  return Axios.get(url, { responseType: "arraybuffer" }).then((response) =>
    Buffer.from(response.data, "binary").toString("base64")
  );
}

const ProjectDetails = () => {
  let { id } = useParams();
  const configData = { id: id };
  const [resultData, setResultData] = useState({});
  const fileUrl = "https://xtremeapi.xtremecardz.com/api/orders/files/";
  const baseUrl = BASE_URL;

  const downloadFile = async () => {
    let projectCodeId = resultData.projectFiles.project_code;
    //FileDownload(results.data, `${id}.jpg`);
    const results = await customersService.getProjectCodeFiles({
      code: projectCodeId,
    });

    results.data.forEach((val) => {
      console.log(baseUrl + val.file_type);
      let imageFileData = getBase64(baseUrl + val.file_type);
      zip.file(`x_${val.filename}`, imageFileData, {
        base64: true,
      });
    });

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(
        content,
        `xtreme_${(Math.random() + 1).toString(36).substring(7)}.zip`
      );
    });
  };

  let isArray = function (a) {
    return !!a && a.constructor === Array;
  };

  let isObject = function (a) {
    return !!a && a.constructor === Object;
  };

  const newDownloadFile = () => {
    const token = localStorage.getItem("token");
    Axios({
      url: fileUrl + id,
      method: "GET",
      responseType: "blob",
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      FileDownload(response.data, `${id}.jpg`);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const results = await customersService.getProjectDetails(configData);
      setResultData(results.data);
      console.log(results);
    };
    fetchData();
  }, []);

  return (
    <BodyWidget>
      <Header category="Page" title="" backButton={true} />

      <section className="bg-white dark:bg-gray-900">
        <div className="container px-6 py-10 mx-auto">
          <div className="lg:flex lg:items-center">
            <div className="w-full space-y-12 lg:w-1/2 ">
              <div>
                <h1 className="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white">
                  {resultData.fullname}
                </h1>

                <div className="mt-2">
                  <span className="inline-block w-40 h-1 rounded-full bg-blue-500"></span>
                  <span className="inline-block w-3 h-1 ml-1 rounded-full bg-blue-500"></span>
                  <span className="inline-block w-1 h-1 ml-1 rounded-full bg-blue-500"></span>
                </div>
              </div>

              <div className="md:flex md:items-start md:-mx-4">
                <div className="mt-4 md:mx-4 md:mt-0">
                  <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white">
                    Address &amp; Location
                  </h1>

                  <p className="mt-3 text-gray-500 dark:text-gray-300">
                    {resultData.company_address} <br />
                  </p>
                </div>
              </div>

              <div className="md:flex md:items-start md:-mx-4">
                <span className="inline-block p-2 text-blue-500 bg-blue-100 rounded-xl md:mx-4 dark:text-white dark:bg-blue-500"></span>

                <div className="mt-4 md:mx-4 md:mt-0">
                  <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white">
                    Company Name
                  </h1>

                  <p className="mt-3 text-gray-500 dark:text-gray-300">
                    {resultData.company_name} <br />
                    {resultData.website_link} <br />
                    {resultData.email}
                    <h3 className="text-2xl font-black text-gray-700 capitalize dark:text-white">
                      {resultData.phone_number}
                    </h3>
                    <div className="mt-2">
                      <span className="inline-block w-40 h-1 rounded-full bg-blue-500"></span>
                      <span className="inline-block w-3 h-1 ml-1 rounded-full bg-blue-500"></span>
                      <span className="inline-block w-1 h-1 ml-1 rounded-full bg-blue-500"></span>
                    </div>
                    {`Designation: ${resultData.designation}`} <br />
                    {`Cards Number: ${resultData.number_of_cards}`} <br />
                  </p>
                  <p className="mt-3 text-gray-500 dark:text-gray-300"></p>
                </div>
              </div>

              <div className="md:flex md:items-start md:-mx-4">
                <span className="inline-block p-2 text-blue-500 bg-blue-100 rounded-xl md:mx-4 dark:text-white dark:bg-blue-500"></span>

                <div className="mt-4 md:mx-4 md:mt-0">
                  <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white">
                    Card Back Content
                  </h1>

                  <p className="mt-3 text-gray-500 dark:text-gray-300">
                    {resultData.back_content}
                  </p>
                </div>
              </div>
            </div>

            <div className="hidden lg:flex lg:items-center lg:w-1/2 lg:justify-center">
              <img
                className="w-[25rem] h-[25rem] object-cover rounded-full"
                src={`https://xtremeapi.xtremecardz.com/api/${resultData.image}`}
              />
            </div>
          </div>

          <hr className="border-gray-200 my-12 dark:border-gray-700" />

          <div className="grid">
            <div className="flex items-center justify-center">
              <Button
                className="ml-2 font-bold"
                text="Download Assets"
                bgColor="#03C9D7"
                color="white"
                width="full"
                borderRadius="10px"
                additionalCallBack={downloadFile}
              />
              <Button
                className="ml-2 font-bold"
                text="Download Image"
                bgColor="#03C9D7"
                color="white"
                width="full"
                borderRadius="10px"
                additionalCallBack={() => newDownloadFile()}
              />
            </div>
          </div>
        </div>
      </section>
    </BodyWidget>
  );
};

export default ProjectDetails;

import React from "react";

import { ChartsHeader, LineChart } from "../../component";
import BodyWidget from "../../component/BodyWidget";

const Line = () => (
  <BodyWidget>
    <ChartsHeader category="Line" title="Inflation Rate" />
    <div className="w-full">
      <LineChart />
    </div>
  </BodyWidget>
);

export default Line;

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useStateContext } from "../contexts/ContextProvider";

import { Button } from "../component";
// import userRefreshToken from "../hooks/userRefreshToken";
import { login, reset } from "../features/auth/authSlice";
import Spinner from "../component/Spinner";

const SignIn = () => {
  const { currentColor, setAuthentication } = useStateContext();
  // const { dispatch } = useAuthContextApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const userRef = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //const refresh = userRefreshToken();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    if (isError) {
      console.log(message);
    }
    if (isSuccess || user) {
      setAuthentication(true);
      navigate("/dashboard");
    }

    dispatch(reset());
  }, [
    user,
    isError,
    isSuccess,
    message,
    navigate,
    dispatch,
    setAuthentication,
  ]);

  const handleLoginEvent = () => {
    //Api Call
    if (email && password) {
      const userData = {
        email,
        password,
      };
      dispatch(login(userData));
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center align-middle absolute w-full h-full bg-white -z-20">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {/* <div className="flex items-center justify-center align-middle absolute w-full h-full bg-white">
        <Spinner />
      </div> */}
      <div className="bg-gray-100 flex flex-col items-center justify-center min-h-screen md:py-2">
        <main className="flex items-center w-full px-2 md:px-20">
          <div className="hidden md:inline-flex flex-col flex-1 space-y-1 p-10">
            <p className="text-6xl text-gray-500 font-bold">Administrator</p>
            <p
              className="font-medium text-lg leading-1"
              style={{ color: "#03c9d7" }}
            >
              Explore your interests, check activities of users of your
              application, try check am now at least na only you waka come
            </p>
          </div>
          <div className="bg-white rounded-2xl shadow-2xl p-6 flex flex-col w-full md:w-2/5 items-center max-w-4xl transition duration-1000 ease-out">
            <h2 className="p-3 text-3xl text-gray-700 font-black">
              Xtreme Admin
            </h2>
            <div className="inline-block border-[1px] justify-center w-20 border-blue-400 border-solid"></div>
            <h3
              className="text-xl font-semibold pt-2 m-6"
              style={{ color: "#03c9d7" }}
            >
              Sign In!
            </h3>

            <div className="flex flex-wrap items-center justify-center">
              <input
                type="email"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                className="rounded-2xl pr-5 pl-5 p-3 w-full border-[1px] mb-4 focus:shadow-md focus:outline-none text-gray-400 focus:ring-0"
                placeholder="Type Your Email"
                value={email}
                required
              ></input>
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                className="rounded-2xl pr-5 pl-5 p-3 w-full border-[1px] mb-10 focus:shadow-md focus:outline-none text-gray-400 focus:ring-0"
                placeholder="Type Your Secret Password"
                value={password}
              ></input>

              <Button
                color="white"
                bgColor={currentColor}
                text="Sign In"
                borderRadius="10px"
                width="full"
                additionalCallBack={handleLoginEvent}
              />
            </div>
            <div className="inline-block border-[1px] justify-center w-20 border-blue-400 border-solid"></div>

            <p
              className="text-gray-400 mb-4 text-sm font-medium cursor-pointer mt-3"
              onClick={() => {}}
            >
              Don't have an account? Create a New Account
            </p>
          </div>
        </main>
      </div>
    </>
  );
};

export default SignIn;

import React from "react";
import { ChartsHeader, LineChart } from "../../component";
import BodyWidget from "../../component/BodyWidget";

const ColorMapping = () => {
  return (
    <BodyWidget>
      <ChartsHeader category="Line" title="Color Mapping" />
      <div className="w-full">
        <LineChart />
      </div>
    </BodyWidget>
  );
};

export default ColorMapping;

import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import { BiArrowBack } from "react-icons/bi";

const Header = ({ category, title, backButton }) => {
  const navigate = useNavigate();
  return (
    <div className=" mb-10">
      <p className="text-lg text-gray-400">{category}</p>
      <p className="text-3xl font-semibold tracking-tight text-slate-900">
        {backButton ? (
          <Button
            text={title}
            size="auto"
            icon={<BiArrowBack />}
            additionalCallBack={() => navigate(-1)}
            inlineFlex="inline-flex"
          />
        ) : (
          title
        )}
      </p>
    </div>
  );
};

export default Header;

import React from "react";

import { useStateContext } from "../contexts/ContextProvider";

const Button = ({
  className,
  icon,
  bgColor,
  color,
  bgHoverColor,
  size,
  text,
  borderRadius,
  width,
  additionalCallBack,
  inlineFlex,
}) => {
  const { setIsClicked, initialState } = useStateContext();

  return (
    <button
      type="button"
      onClick={() => {
        setIsClicked(initialState);
        additionalCallBack();
      }}
      style={{ backgroundColor: bgColor, color, borderRadius }}
      className={`${className} text-${size} p-3 w-${width} hover:drop-shadow-xl hover:bg-${bgHoverColor} ${inlineFlex} items-center`}
    >
      {icon} {text}
    </button>
  );
};

export default Button;

import React from "react";
// import { BsCurrencyDollar } from "react-icons/bs";
import { IoIosMore, IoMdCart, IoMdWallet } from "react-icons/io";
import { GoPrimitiveDot } from "react-icons/go";
import { Stacked, Button, SparkLine, LineChart } from "../component";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { useStateContext } from "../contexts/ContextProvider";
import product9 from "../data/product9.jpg";

import {
  earningData,
  medicalproBranding,
  recentTransactions,
  weeklyStats,
  dropdownData,
  SparklineAreaData,
  ecomPieChartData,
} from "../data/dummy";

const DropDown = ({ currentMode }) => (
  <div className="w-28 border-1 border-color px-2 py-1 rounded-md">
    <DropDownListComponent
      id="time"
      fields={{ text: "Time", value: "Id" }}
      style={{ border: "none", color: currentMode === "Dark" && "white" }}
      value="1"
      dataSource={dropdownData}
      popupHeight="220px"
      popupWidth="120px"
    />
  </div>
);

const Ecommerce = () => {
  const { currentColor, currentMode } = useStateContext();

  return (
    <div className="mt-12">
      <div className="flex flex-wrap lg:flex-nowrap justfiy-center">
        <div
          className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg 
        h-44 rounded-xl w-full lg:w-6/12 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat 
        bg-cover bg-center shadow-lg shadow-gray-100"
        >
          <div className="flex justify-between items-center">
            <div>
              <p className="font-light text-gray-400">
                Congratulations Zeoharlem
              </p>
              <p className="font-medium text-2xl mt-5">$63,440</p>
            </div>
          </div>
          <div className="mt-1">
            <Button
              color="white"
              bgColor="#03C9D7"
              text="Download"
              borderRadius="10px"
              size="md"
            />
          </div>
        </div>
        <div
          className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg 
        h-44 rounded-xl w-full lg:w-5/12 p-8 pt-9 m-3 shadow-lg shadow-gray-100"
        >
          <div className="flex justify-between items-center">
            <div>
              <p className="font-light text-gray-400">Purchases</p>
              <p className="font-medium text-2xl mt-6">2,367</p>
              <p className="mt-1 text-sm text-gray-400">Monthly Sales</p>
            </div>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: currentColor,
              }}
              className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
            >
              <IoMdCart />
            </button>
          </div>
        </div>
      </div>

      <div className="flex m-3 flex-wrap lg:flex-nowrap justify-between gap-5 items-center">
        {earningData.map((item) => (
          <div
            key={item.title}
            className="bg-white h-44 dark:text-gray-200 
              dark:bg-secondary-dark-bg md:w-1/4  p-4 pt-9 rounded-2xl shadow-lg shadow-gray-100"
          >
            <button
              type="button"
              style={{
                color: item.iconColor,
                backgroundColor: item.iconBg,
              }}
              className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
            >
              {item.icon}
            </button>
            <p className="mt-3">
              <span className="text-lg font-normal">{item.amount}</span>
              <span className={`text-sm text-${item.pcColor} ml-2`}>
                {item.percentage}
              </span>
            </p>
            <p className="text-sm text-gray-400 mt-1">{item.title}</p>
          </div>
        ))}
      </div>

      <div className="flex flex-nowrap justify-center">
        <div className="md:w-1/2 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3 shadow-lg shadow-gray-100">
          <div className="flex justify-between">
            <p className="text-xl font-semibold">Weekly Stats</p>
            <button
              type="button"
              className="text-xl font-semibold text-gray-500"
            >
              <IoIosMore />
            </button>
          </div>

          <div className="mt-10 ">
            {weeklyStats.map((item) => (
              <div
                key={item.title}
                className="flex justify-between mt-4 w-full"
              >
                <div className="flex gap-4">
                  <button
                    type="button"
                    style={{ background: item.iconBg }}
                    className="text-2xl hover:drop-shadow-xl text-white rounded-full p-3"
                  >
                    {item.icon}
                  </button>
                  <div>
                    <p className="text-md font-semibold">{item.title}</p>
                    <p className="text-sm text-gray-400">{item.desc}</p>
                  </div>
                </div>

                <p className={`text-${item.pcColor}`}>{item.amount}</p>
              </div>
            ))}
            <div className="mt-4">
              <SparkLine
                currentColor={currentColor}
                id="area-sparkLine"
                height="160px"
                type="Area"
                data={SparklineAreaData}
                width="320"
                color="rgb(242, 252, 253)"
              />
            </div>
          </div>
        </div>
        <div className="md:w-1/2 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3 shadow-lg shadow-gray-100">
          <div className="flex justify-between">
            <p className="text-xl font-semibold">MedicalPro Branding</p>
            <button
              type="button"
              className="text-xl font-semibold text-gray-400"
            >
              <IoIosMore />
            </button>
          </div>
          <p className="text-xs cursor-pointer hover:drop-shadow-xl font-semibold rounded-lg w-24 bg-orange-400 py-0.5 px-2 text-gray-200 mt-10">
            16 APR, 2021
          </p>

          <div className="flex gap-4 border-b-1 border-color mt-6">
            {medicalproBranding.data.map((item) => (
              <div
                key={item.title}
                className="border-r-1 border-color pr-4 pb-2"
              >
                <p className="text-xs text-gray-400">{item.title}</p>
                <p className="text-sm">{item.desc}</p>
              </div>
            ))}
          </div>
          <div className="border-b-1 border-color pb-4 mt-2">
            <p className="text-md font-semibold mb-2">Teams</p>

            <div className="flex gap-4">
              {medicalproBranding.teams.map((item) => (
                <p
                  key={item.name}
                  style={{ background: item.color }}
                  className="cursor-pointer hover:drop-shadow-xl text-white py-0.5 px-3 rounded-lg text-xs"
                >
                  {item.name}
                </p>
              ))}
            </div>
          </div>
          <div className="mt-2">
            <p className="text-md font-semibold mb-2">Leaders</p>
            <div className="flex gap-4">
              {medicalproBranding.leaders.map((item, index) => (
                <img
                  key={index}
                  className="rounded-full w-8 h-8"
                  src={item.image}
                  alt=""
                />
              ))}
            </div>
          </div>
          <div className="flex justify-between items-center mt-5 border-t-1 border-color">
            <div className="mt-3">
              <Button
                color="white"
                bgColor={currentColor}
                text="Add"
                borderRadius="10px"
              />
            </div>

            <p className="text-gray-400 text-sm">36 Recent Transactions</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecommerce;

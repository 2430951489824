import React from "react";
import { ChartsHeader, LineChart } from "../component";
import BodyWidget from "../component/BodyWidget";

const ColorPicker = () => {
  return (
    <BodyWidget>
      <ChartsHeader category="Line" title="Color Picker" />
      <div className="w-full">
        <LineChart />
      </div>
    </BodyWidget>
  );
};

export default ColorPicker;

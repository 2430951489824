import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { FiUserPlus } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Navbar, Footer, Sidebar, ThemeSettings } from "../component";
import { useStateContext } from "../contexts/ContextProvider";
import {
  Registration,
  Calendar,
  Ecommerce,
  Orders,
  Employees,
  Customer,
  Kanban,
  Editor,
  ColorPicker,
  ColorMapping,
  Line,
  Area,
  Bar,
  Pie,
  Financial,
  Pyramid,
  Stacked,
  SignIn,
} from "../pages";
import ProjectDetails from "./ProjectDetails";

const Dashboard = () => {
  const {
    isLoggedIn,
    setCurrentColor,
    setCurrentMode,
    // currentMode,
    activeMenu,
    // currentColor,
    themeSettings,
    // setThemeSettings,
    setTranslateEvent,
    setTranslateEffect,
    translateEffect,
    HandleRequireAuthEvent,
  } = useStateContext();

  const handleTranslateEvent = () => {
    setTranslateEvent(false);
  };

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
    handleTranslateEvent();
  }, [setCurrentColor, setCurrentMode]);

  return (
    <div className="flex relative dark:bg-main-dark-bg">
      <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
        <TooltipComponent content="Add Account" position="Top">
          <button
            className="text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white"
            style={{ background: "#03C9D7", borderRadius: "50%" }}
          >
            <FiUserPlus />
          </button>
        </TooltipComponent>
      </div>
      {activeMenu ? (
        <div
          className={`w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ${translateEffect} ease-in-out duration-300`}
        >
          <Sidebar />
        </div>
      ) : (
        <div className="w-0  dark:bg-secondary-dark-bg">
          <Sidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? "dark:bg-main-bg bg-main-bg min-h-screen md:ml-72 w-full"
            : "dark:bg-main-bg bg-main-bg min-h-screen w-full flex-2"
        }
      >
        <div
          className="sticky top-0 bg-main-bg dark:bg-main-darl-bg navbar w-full"
          style={{ zIndex: "1" }}
        >
          <Navbar />
        </div>

        <div>
          {themeSettings && <ThemeSettings />}
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path="/signin" element={<SignIn />} />

            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route
              path="/dashboard"
              element={
                <HandleRequireAuthEvent>
                  <Ecommerce />
                </HandleRequireAuthEvent>
              }
            />
            <Route path="/users" element={<Registration />} />
            <Route
              path="/calendar"
              element={
                <HandleRequireAuthEvent>
                  <Calendar />
                </HandleRequireAuthEvent>
              }
            />
            {/* dashboard  */}
            {/* <Route path="/" element={<Ecommerce />} /> */}

            {/* pages  */}
            <Route path="/orders" element={<Orders />} />
            <Route path="/employees" element={<Employees />} />
            <Route path="customers" element={<Customer />} />
            <Route path="/customers/:id" element={<ProjectDetails />} />

            {/* apps  */}
            <Route path="/kanban" element={<Kanban />} />
            <Route path="/editor" element={<Editor />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/color-picker" element={<ColorPicker />} />

            {/* charts  */}
            <Route
              path="/line"
              element={
                <HandleRequireAuthEvent>
                  <Line />
                </HandleRequireAuthEvent>
              }
            />
            <Route
              path="/area"
              element={
                <HandleRequireAuthEvent>
                  <Area />
                </HandleRequireAuthEvent>
              }
            />
            <Route
              path="/bar"
              element={
                <HandleRequireAuthEvent>
                  <Bar />
                </HandleRequireAuthEvent>
              }
            />
            <Route
              path="/pie"
              element={
                <HandleRequireAuthEvent>
                  <Pie />
                </HandleRequireAuthEvent>
              }
            />
            <Route
              path="/financial"
              element={
                <HandleRequireAuthEvent>
                  <Financial />
                </HandleRequireAuthEvent>
              }
            />
            <Route
              path="/color-mapping"
              element={
                <HandleRequireAuthEvent>
                  <ColorMapping />
                </HandleRequireAuthEvent>
              }
            />
            <Route
              path="/pyramid"
              element={
                <HandleRequireAuthEvent>
                  <Pyramid />
                </HandleRequireAuthEvent>
              }
            />
            <Route
              path="/stacked"
              element={
                <HandleRequireAuthEvent>
                  <Stacked />
                </HandleRequireAuthEvent>
              }
            />
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;

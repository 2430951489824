import React, { useState, useEffect, useRef } from "react";

import { Header } from "../component";
import BodyWidget from "../component/BodyWidget";
import Datatable from "../component/mui-components/Datatable";
import { getCustomerProject, reset } from "../features/customersSlice";
import customersService from "../api/customers.api";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";

const BASE_URL = "https://xtremeapi.xtremecardz.com/api/";

const toCapitalizeWord = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase()
  );

const columns = [
  { field: "_id", headerName: "ID", width: 70 },
  {
    field: "fullname",
    headerName: "User",
    width: 230,
    renderCell: (params) => {
      const imageLink = `${BASE_URL}${params.row.image}`;
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={imageLink} alt="avatar" />
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            fontSize="13px"
            variant="subtitle1"
            component="div"
          >
            {toCapitalizeWord(params.row.fullname)}
          </Typography>
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },
  {
    field: "phone_number",
    headerName: "Phone",
    width: 230,
  },

  {
    field: "designation",
    headerName: "Designation",
    width: 230,
  },
  {
    field: "number_of_cards",
    headerName: "No(s) Card",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.number_of_cards}`}>
          {params.row.number_of_cards}
        </div>
      );
    },
  },
];

const Customer = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const effectRunning = useRef(false);
  const { customers, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.customers
  );

  const { user } = useSelector((state) => state.auth);
  const configData = { page: 1, limit: 10 };
  useEffect(() => {
    //token = user.token;

    if (effectRunning.current === true) {
      dispatch(getCustomerProject(configData));
    }
    return () => {
      console.log("unmounted");
      effectRunning.current = true;
    };
  }, []);

  useEffect(() => {
    if (isError) {
      console.log(message);
    }
    const fetchData = async () => {
      const results = await customersService.getCustomerProject(configData);
      setData(results.data);
      setIsLoadingData(false);
    };
    fetchData();
    // if (isSuccess || customers) {
    //   //console.log(customers.data);
    //   //setData(customers.data);
    // }
    //dispatch(reset());
  }, [isLoadingData, data]);
  //customers, isError, isSuccess, message, dispatch, isLoadingData, data

  return (
    <BodyWidget>
      <Header category="Page" title="Customers" />
      <Datatable columns={columns} data={data} isLoadingData={isLoadingData} />
    </BodyWidget>
  );
};

export default Customer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customersService from "../api/customers.api";

const customers = null;

const initialState = {
  customers: customers ? customers : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//Get Customers Project User
export const getCustomerProject = createAsyncThunk(
  "customers/projects",
  async (customers, thunkAPI) => {
    try {
      return await customersService.getCustomerProject(customers);
    } catch (error) {
      const message = error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.customers = action.payload;
      })
      .addCase(getCustomerProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.customers = null;
      });
  },
});

export const { reset } = customersSlice.actions;
export default customersSlice.reducer;
